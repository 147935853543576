import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
);

const doughnutOptions = {
  resizeDelay: 50,
};

const barOptions = {
  resizeDelay: 50,
  indexAxis: 'y' as const,
  scales: {
    x: {
      ticks: {
        callback: (value: any) => {
          if (Math.floor(value) === value) {
            return value;
          }

          return undefined;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

type Stats = { [key: string]: number };

type Data = {
  stats: Stats;
  multiple: boolean;
  labels: string[];
};

type Props = {
  data: Data;
};

function makeData(stats: Stats, labels: string[]): any {
  const data = Object.values(stats);

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderWidth: 1,
      },
    ],
  };
}

const NoAnswer = () => <div>No answer</div>;

export const SurveyChart = ({ data }: Props) => {
  const { stats, multiple, labels } = data;
  const chartData = makeData(stats, labels);
  if (labels.length === 0) {
    return <NoAnswer />;
  }

  if (multiple) {
    return <Bar data={chartData} options={barOptions} />;
  }

  return <Doughnut data={chartData} options={doughnutOptions} />;
};
